import axios from 'axios'

import router from '@src/Routes'
import useStore from '@src/store'
import useOCRStore from '@components/OCRViewer/store'

import styles from './styles'

import { OnboardingStep } from '@type/onboarding'
import routes from '@src/Routes/routes'
import { sendEvent } from '@helpers/hooks/useAmplitude'
import api from '@api/api'
import { PATHS as TeamPATHS } from '@queries/Teams'
import { PATHS as AccountPATHS } from '@queries/Account'
import { Account } from '@src/models/Account'

// Intercom customization

const customContainerStyle = { 'font-family': 'Poppins' }

const progressPattern = /<progress:(.*?)>/
const navigationPattern = /<navigate:(.*?)>/
const actionPattern = /<action:(.*?)>/

const setOnboardingStep = useStore.getState().setOnboardingStep
const setStartPath = useStore.getState().setStartPath
const setIsOnboarding = useStore.getState().setIsOnboarding
const isOnboarding = useStore.getState().isOnboarding

const findElementWithExactText = (document: Document, text: string | RegExp, parrentNode?: Node | null) => {
  const walker = document.createTreeWalker(parrentNode || document.body, NodeFilter.SHOW_TEXT, null)
  let node
  const matches = []

  while ((node = walker.nextNode())) {
    if (node.nodeValue?.trim()?.match(text)) {
      let parentHasExactText = false
      if (node.parentNode) {
        for (const childNode of node.parentNode.childNodes) {
          if (childNode !== node && !!childNode.textContent?.trim().match(text)) {
            parentHasExactText = true
            break
          }
        }
      }

      if (!parentHasExactText) {
        matches.push(node.parentElement)
      }
    }
  }

  return matches
}

const applyModifier = (document: Document, pattern: RegExp, callback: (response: string | null) => void) => {
  const anchor = findElementWithExactText(document, pattern).pop()
  if (anchor) {
    const match = anchor.textContent?.match(pattern)
    if (match?.[1]) {
      const response = match[1]
      callback(response)
    } else {
      callback(null)
    }
    anchor.textContent = anchor.textContent?.replace(pattern, '') || ''
  }
}

const handleOCR = async (button?: HTMLButtonElement | null) => {
  const fileResponse = await axios.get('/onboard/bill.pdf', {
    responseType: 'blob',
  })
  const file = new File([fileResponse.data as Blob], 'bill.pdf', {
    lastModified: Date.now(),
    type: 'application/pdf',
  })
  useOCRStore?.getState?.()?.setFile?.(file)
  if (button) {
    button.disabled = true
    button.classList.add('loading')
    const spinner = document.createElement('span')
    spinner.classList.add('spinner')
    button.appendChild(spinner)
    useOCRStore.subscribe(
      (state) => state.isAnalyzing,
      (isAnalizing) => {
        if (!isAnalizing) {
          button.disabled = false
          button.classList.remove('loading')
          button.removeChild(spinner)
        }
      },
    )
  }
}

const addStyles = (currentStyle: CSSStyleDeclaration, newStyles: Record<string, string>) => {
  Object.keys(newStyles).forEach((key) => {
    currentStyle.setProperty(key, newStyles[key])
  })
}

const addCss = (document: Document, styles: string) => {
  const style = document.createElement('style')
  style.innerText = styles
  document.head?.appendChild?.(style)
}

const addOnLoadListenerToIframe = (iframe: HTMLIFrameElement) => {
  if (iframe.contentWindow?.document) {
    const document = iframe.contentWindow.document
    let navigationRoute: string | null = null
    let action: string | null = null

    addCss(document, styles)

    const container = document?.getElementsByClassName('intercom-tour-step')?.[0]

    document.querySelector('[aria-label="Snooze"]')?.remove?.()
    document.querySelector('[aria-label="Close"]')?.remove()

    const next = document?.querySelector('[role="button"]')?.querySelector('button')
    const authoredContainer = document.querySelector('.intercom-authored-container') as HTMLDivElement
    const stepContent = document.querySelector('.intercom-tour-step-content') as HTMLDivElement

    if (authoredContainer) {
      const author = authoredContainer.querySelector(
        '.intercom-authored-container > div:not(.intercom-tour-step-header):not(.intercom-tour-step-content):has(+.intercom-tour-step-header)',
      )
      author?.remove()

      authoredContainer.classList.add('authoredContainer')
      const bottomElement = authoredContainer.querySelector(
        '.intercom-tour-step-content+div:not(.intercom-tour-step-header):not(.intercom-tour-step-content)',
      )
      findElementWithExactText(document, ' of ', bottomElement)?.pop()?.remove()

      if (!bottomElement?.childNodes?.length) {
        bottomElement?.remove()
      }
    }
    if (stepContent) {
      stepContent.classList.add('stepContent')
    }
    if (container) {
      addStyles((container as HTMLDivElement).style, customContainerStyle)
    }
    if (next) {
      next.classList.add('actionButton')
      const listener = (e: MouseEvent) => {
        if (navigationRoute && e.isTrusted) {
          router.navigate(navigationRoute)
        }
        if (action === 'end') {
          setOnboardingStep(null)
        }
        next.removeEventListener('click', listener)
      }
      next.addEventListener('click', listener)
    }

    applyModifier(document, progressPattern, (response) => {
      if (response === 'welcome' && next) {
        const button = document.createElement('button')
        next.classList.add('closeButton')
        next.style.marginRight = '0px'
        button.classList.add('actionButton')
        button.style.marginRight = '8px'

        button.innerText = 'Get a Demo'
        next.parentNode?.insertBefore(button, next)

        button.onclick = async () => {
          sendEvent('onboarding-book-demo')
          const demoPath = new URL(import.meta.env.VITE_INAPP_DEMO_LINK || '')
          const resUser = await api.get<Account>(AccountPATHS.me)
          demoPath.searchParams.set('firstname', resUser.data.name?.split(' ')?.[0] || '')
          demoPath.searchParams.set('lastname', resUser.data.name?.split(' ')?.[1] || '')
          demoPath.searchParams.set('email', resUser.data.email || '')
          demoPath.searchParams.set('company', resUser.data.team?.name || '')
          const resAnswers = await api.get<[number, string][]>(TeamPATHS.questionnaireAnswers)
          const phone = resAnswers.data.find((answer) => answer[1].startsWith('+'))?.[1]
          demoPath.searchParams.set('phone', phone || '')

          window.open(demoPath.toString(), '_blank')
        }
      }
      setOnboardingStep(response as OnboardingStep)
    })

    applyModifier(document, navigationPattern, (response) => {
      navigationRoute = response
    })
    applyModifier(document, actionPattern, async (response) => {
      action = response
      if (response === 'OCR') {
        handleOCR(next)
      }
      if (response === 'end' && next) {
        sendEvent('onboarding-completed')

        const button = document.createElement('button')
        next.classList.add('closeButton')
        button.classList.add('actionButton')

        button.innerText = 'Close and go to Dashboard'
        next.parentNode?.appendChild(button)
        button.onclick = () => {
          router.navigate(routes.private.dashboard)
          next.click()
        }
      }
    })
  }
}

const popupAppearListener = (iframe: HTMLIFrameElement) => {
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      mutation.addedNodes.forEach((node) => {
        const popupAnyElement = node.ownerDocument?.getElementsByClassName('intercom-tour-step')

        if (popupAnyElement?.length) {
          addOnLoadListenerToIframe(iframe as HTMLIFrameElement)
        }
      })
    })
  })
  if (iframe.contentDocument) {
    observer.observe(iframe.contentDocument.body, {
      childList: true,
      subtree: true,
    })
  }
}

const observeIframes = () => {
  let frame: Element | null | undefined = null
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      mutation.addedNodes.forEach((node) => {
        const iframe = node.ownerDocument?.querySelector('[name="intercom-tour-frame"]')

        if (iframe !== frame) {
          frame = iframe
          if (iframe) {
            if (!isOnboarding) {
              setIsOnboarding(true)
            }
            setStartPath(window.location.pathname + window.location.search)
            popupAppearListener(iframe as HTMLIFrameElement)
          }
        }
      })
    })
  })

  observer.observe(document.body, {
    childList: true,
    subtree: true,
  })

  setTimeout(() => {
    if (!useStore.getState().isOnboarding) {
      setOnboardingStep(null)
    }
  }, 7000)
}

observeIframes()
