import { emerald, gray, slate } from 'tailwindcss/colors'

export const styles = `
    @font-face {
      font-family: Poppins;
      font-weight: normal;
      src: url("/fonts/Poppins/Poppins-Regular.eot") format("eot"), url("/fonts/Poppins/Poppins-Regular.woff") format("woff"), url("/fonts/Poppins/Poppins-Regular.woff2") format("woff2");
      font-display: swap;
    }
    @font-face {
      font-family: Poppins;
      font-weight: 500;
      src: url("/fonts/Poppins/Poppins-Medium.eot") format("eot"), url("/fonts/Poppins/Poppins-Medium.woff") format("woff"), url("/fonts/Poppins/Poppins-Medium.woff2") format("woff2");
      font-display: swap;
    }
    @font-face {
      font-family: Poppins;
      font-weight: bold;
      src: url("/fonts/Poppins/Poppins-SemiBold.eot") format("eot"), url("/fonts/Poppins/Poppins-SemiBold.woff") format("woff"), url("/fonts/Poppins/Poppins-SemiBold.woff2") format("woff2");
      font-display: swap;
    }

    body,
    button {
      font-family: Poppins;
      transition: background-color 0.3s;
    }
    h1,
    h2 {
      font-family: Poppins;
      color: #662DEF !important;
    }
    b {
      font-weight: 500;
    }

    button.actionButton {
      background-color: ${emerald[500]};
      color: white;
      padding: 6px 16px;
      border-radius: 6px;
      opacity: 1;
    }

    button.actionButton.closeButton {
      background-color: transparent;
      margin-right: 8px;
      border: 1px solid ${gray[200]};
      color: #343A40;
    }
    button.actionButton.closeButton:hover {
      background-color: ${gray[50]};
    }


    button.actionButton:hover {
      background-color: ${emerald[700]};
    }
    .authoredContainer {
      padding-top: 16px;
    }
    .stepContent {
      padding-left: 16px;
      padding-right: 16px;
    }
    button.actionButton.loading {
      display: flex;
      justify-content: center;
      color: transparent;
    }
    @keyframes spinner-border {
      to {
        transform: rotate(360deg);
      }
    }
    .spinner {
      width: 16px;
      height: 16px;
      display: inline-block;
      vertical-align: text-bottom;
      border: 2px solid white;
      border-right-color: transparent;
      border-radius: 50%;
      position: absolute;
      text-align: center;
      animation: spinner-border 0.75s linear infinite;
    }
`

export default styles
